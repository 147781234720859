<!-- (카드형)설비 유형 목록 선택 -->
<template>
    <div>
        <header-box-component :title="`${$t('운영 기준정보 관리')} > ${$t('설비 인벤토리 관리')}`" />
        <div class="card-container">
            <div class="row">
                <div v-for="(equipType) in equipTypeList" :key="equipType.equipType" 
                    class="col-lg-4 col-md-6 col-12 mb-3 pointer-cursor" @click="goToDetail(equipType.equipType)">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="card-left">
                                        <span class="card-title mb-0" style="font-size: 2rem;">
                                            <!-- <i class="fa fa-edit pointer-cursor"></i> -->
                                            <i :class="equipType.icon ?? ''"></i>
                                        </span>
                                        <!-- <h6 class="card-subtitle mb-2 text-muted">{{ equip.equipType }}</h6> -->
                                        <span class="card-title mb-0" style="font-weight: 700; font-size: 0.8rem;">{{ equipType.equipType ?? '-' }}</span>
                                    </div>
                                    <div class="card-right">
                                        <span class="card-title" style="font-weight: 600; font-size: 0.8rem; ">{{ equipType.equipTypeName ?? '-' }}</span>
                                        <span class="card-subtitle text-muted">{{ equipType.description ?? '-' }}</span>
                                    </div>
                                    <div class="system-stats">
                                        <div class="badge badge-secondary">
                                            <div class="row m-0 align-items-center" style="width: 100%; height: 100%;">
                                                <span class="col-9 p-0 text-right">{{ "Device:" }}</span>
                                                <span class="col-3 p-0">{{  equipType.deviceCnt ?? 0 }}</span>
                                            </div>
                                        </div>
                                        <span class="badge badge-secondary">
                                            <div class="row m-0 align-items-center" style="width: 100%; height: 100%;">
                                                <span class="col-9 p-0 text-right">{{ "Module:" }}</span>
                                                <span class="col-3 p-0">{{  equipType.moduleCnt ?? 0 }}</span>
                                            </div>
                                        </span>
                                        <span class="badge badge-primary">
                                            <!-- {{ `Equip: ${equipType.equipCnt ?? 0}` }} -->
                                            <div class="row m-0 align-items-center" style="width: 100%; height: 100%;">
                                                <span class="col-9 p-0 text-right">{{ "Equip:" }}</span>
                                                <span class="col-3 p-0">{{  equipType.equipCnt ?? 0 }}</span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 아이콘 사이즈업 -->
    </div>
</template>

<script>
import HeaderBoxComponent from "@views/component/headerBox/analysis/Header.vue";
// import backEndApi from '@src/api/backEndApi';

export default {
    components: {
        HeaderBoxComponent,
    },
    props: {
    },
    data() {
        return {
            equipList: null,
        };
    },
    computed: {
        equipTypeList() {
            return this.$store.state.equipTypeList;
        }
    },
    created() {
        // this.getEquipData();
    },
    mounted() { },
    methods: {
        // async getEquipData() {
        //     const that = this;
        //     let result = await backEndApi.equipTypes.getEquipTypeList("Y");
        //     if (result && result.data) that.equipList = result.data
        // },
        goToDetail(equipType) {
            this.$router.push({ name: 'EquipInfo', query: { equipType: equipType } });
        }
    }
};
</script>

<style scoped>
.card-container {
    background-color: #d3d6db;
    height: 87vh;
    padding: 1rem;
    overflow-y: auto;
}

.card-body {
    padding: 15px 10px 15px 10px !important;
}

.card-left {
    /* border-radius: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 22%;
    height: 140px;
    border-right: 1px solid grey;
}

.card-right {
    /* border-radius: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
}

.card-subtitle {
    padding: 0px 10px 0px 10px;
    /* font-weight: 600; */
}

.system-stats {
    width: 18%;
}

.system-stats .badge {
    width: 100%;
    height: 1.5rem;
    font-size: 12px;
    /* display: flex;
    align-items: center; */
}
.system-stats .badge-secondary {
    margin-bottom: 5px;
}
.card {
    border-radius: 10px;
    border: 1px solid grey;
    box-shadow: 5px 5px 5px grey;
}
</style>